import { Box, Container as MuiContainer, Grid, styled, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { Header } from '../../../components/Header/Header';
import BusinessContract from './icons/business-contract.svg';
import Monetization from './icons/monetization.svg';
import PieLineGraph from './icons/pie-line-graph.svg';
import Lock from './icons/lock.svg';
import SearchBar from './icons/search-bar.svg';
import Hourglass from './icons/hourglass.svg';
import { AdvantageCard } from './AdvantageCard';

const Container = styled(Box)`
  position: relative;
  padding: ${({ theme }) => theme.spacing(30)} 0;
  background-image: url('/images/background.svg');
  background-repeat: no-repeat;
  background-position: calc(50% + 400px) 100px;
  background-size: contain;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: ${({ theme }) => theme.spacing(21.5)} 0;
    background-image: none;
  }
`;

const cards = [
  {
    title: '1. Bis 100% Agio-Rabatt PLUS Cashback',
    text: 'Sie kaufen Fonds in der Regel mit 100 % Rabatt auf die Abschlussprovision (Agio bzw. Ausgabeaufschlag), zusätzlich erhalten Sie bis zu 100 % Gutschrift auf die Bestandsprovision (Cashback).',
    icon: <Monetization />,
  },
  {
    title: '2. Unsere Begrenzung des Provisionseinbehalts',
    text:
      'Als einziger Anbieter im Markt teilen wir mit Ihnen unsere Bestandsprovisionen bis auf beiden Seiten jeweils 15,00 € erreicht sind. \n' +
      'Darüber hinaus erhalten Sie jeden weiteren Cent der Provision zu 100 Prozent.',
    icon: <BusinessContract />,
  },
  {
    title: '3. Absolute Transparenz',
    text: 'Sie erhalten quartalsweise eine konkrete und detaillierte Abrechnung. Transparent weisen wir aus, welche Bestandsprovisionen an Rentablo ausgeschüttet wurden und wie viel Sie davon als Cashback ausgezahlt bekommen.',
    icon: <SearchBar />,
  },
  {
    title: '4. Höchste Sicherheit',
    text:
      'Rentablo arbeitet mit wealthAPI, ein Kontoinformationsdienst der von der BaFin beaufsichtigt wird.\n' +
      'Die wealthAPI GmbH wird regelmäßig überwacht und überprüft. Das dient ihrer Sicherheit und garantiert, dass bei Rentablo alles mit rechten Dingen zugeht.',
    icon: <Lock />,
  },
  {
    title: '5. Riesige Zeitersparnis',
    text: 'Sie erhalten Zugriff auf unseren Steuerhelfer. Dieser spart Ihnen Zeit, Geld und Nerven und liefert die für das Finanzamt relevanten Werte völlig automatisch.',
    icon: <Hourglass />,
  },
  {
    title: '6. Maximaler Überblick',
    text:
      'Sie erhalten Zugriff auf unsere kostenlose Rentablo Depotverwaltungs-App: Hier haben Sie auf Wunsch alle Ihre Konten und Depots auf einem Blick! \n' +
      'Mit der App können Sie Ihre Depots analysieren und Ihre Anlagestrategie optimieren.',
    icon: <PieLineGraph />,
  },
];

const HeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  marginBottom: theme.spacing(21),

  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(14),
  },
}));

export const Advantages = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Container data-sectionid="advantages">
      <MuiContainer>
        <HeaderContainer>
          <Header
            variant="h2"
            align="center"
            headerText={t('landingMain.yourAdvantages.title')}
            sx={{
              marginBottom: theme.spacing(4),
            }}
            id="your-discount"
          />
          <Typography variant="subtitle2" align="center" sx={{ maxWidth: '990px' }}>
            {t('landingMain.yourAdvantages.subtitle')}
          </Typography>
        </HeaderContainer>
        <Grid container spacing={4} alignItems="stretch">
          {cards.map((card) => (
            <AdvantageCard key={card.title} header={card.title} text={card.text} icon={card.icon} />
          ))}
        </Grid>
      </MuiContainer>
    </Container>
  );
};
